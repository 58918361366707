// Required
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

.vehicle-page__container {
  overflow-y: auto;
  overflow-x: hidden;

  @include media-breakpoint-only(xs) {
    max-height: 403px;
  }

  @include media-breakpoint-only(sm) {
    max-height: 487px;
  }

  @include media-breakpoint-only(md) {
    &.vehicle-page__with-link {
      height: 487px;
    }

    &.vehicle-page__without-link {
      height: 457px;
    }
  }

  @include media-breakpoint-up(lg) {
    height: 487px;
  }
}

.vehicle-page__list {
  overflow-y: auto;

  @include media-breakpoint-only(xs) {
    max-height: 358px;
  }

  @include media-breakpoint-only(sm) {
    max-height: 442px;
  }

  @include media-breakpoint-up(md) {
    height: 442px;
  }
}

.vehicle-page__list.is-invalid {
  border: 1px solid #dc3545 !important;
}

.vehicle-page__list-feedback {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
