.confirm-box__display
{
  pointer-events: auto !important;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  max-width: 250px;
  outline: solid;
  outline-width: thin;
  outline-color: black;
  color: black !important;
  font-size: 13px;
  z-index: 100;
}
