@import "../custom.scss";

.btn.start-quote-page__btn-lob {
  color: $btn-text-color;
  border-color: $btn-text-color;
  background-color: $btn-background-color;
  text-align: center;
  font-size: .85rem;
}

.btn.start-quote-page__btn-lob:hover,
.btn.start-quote-page__btn-lob.active,
.btn.start-quote-page__btn-lob:checked {
  color: $btn-highlight-color;
  border-color: $btn-highlight-color;
  background-color: $btn-background-color;
}

//force react-bootstrap-typeahead hint height to be 100% to fix IE issue
.rbt-input-hint {
  height: 100% !important
}

@media (hover: none) { //solves "sticky hover" bug on touch devices
  .btn.start-quote-page__btn-lob:hover {
    color: $btn-text-color;
    border-color: $btn-text-color;
  }

  .btn.start-quote-page__btn-lob.active:hover {
    color: $btn-highlight-color;
    border-color: $btn-highlight-color;
  }
}

.carrier__image {
    max-width: 100%;
}

.start-quote-page__text-overflow-center {
  margin-left: -20px;
  margin-right: -20px;
  text-align: center;
}

/*For Mobile*/
@include media-breakpoint-down(md) {
  .start-quote-page__header-text-main {
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.4rem;
  }

  .start-quote-page__header-text-intro, .start-quote-page__header-text-select {
    font-weight: 500;
    line-height: 1.2;
    font-size: 0.85rem;
  }
}

/*For tablet/desktop*/
@include media-breakpoint-up(md) {
  .start-quote-page__header-text-main {
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.75rem;
  }

  .start-quote-page__header-text-intro {
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.25rem;
  }

  .start-quote-page__header-text-select {
    font-weight: 500;
    line-height: 1.2;
    font-size: 1rem;
  }
}