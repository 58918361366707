// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.carrier__image {
  max-width: 100%;
}

.rate-result__package-heading {
  border-width: 0;
}

.rate-result__text-overflow-center {
  margin-left: -20px;
  margin-right: -20px;
  text-align: center;
}

.rate-result__monthly-premium {
  font-size: 80%;
  font-weight: 400;
}

/*For Mobile*/
@include media-breakpoint-down(md) {
  .rate-result__display {
    white-space: nowrap;
  }

  .rate-result__text {
    white-space: nowrap;
    font-size: .8rem;
  }

  .rate-result__header-text-main {
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.25rem;
  }

  .rate-result__header-text-custom, .rate-result__footer-msg {
    font-weight: 500;
    line-height: 1.2;
    font-size: 1rem;
  }
}

/*For mobile edge cases like iPhone 4*/
@media(max-width: 370px) {
  .rate-result__text {
    white-space: nowrap;
    font-size: .65rem;
  }

  .rate-result__header-text-main {
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.25rem;
  }

  .rate-result__header-text-custom, .rate-result__footer-msg {
    font-weight: 500;
    line-height: 1.2;
    font-size: 1rem;
  }
}

/*For tablet/desktop*/
@include media-breakpoint-up(md) {
  .rate-result__display {
  }

  .rate-result__text {
    white-space: nowrap;
    font-size: .8rem;
  }

  .rate-result__header-text-main {
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.5rem;
  }

  .rate-result__header-text-custom, .rate-result__footer-msg {
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.25rem;
  }
}

/*Special case for Package results on Mobile*/
@include media-breakpoint-down(md) {
  .ppm-rate-result__display {
    white-space: nowrap;
    font-size: .8rem;
  }

  .ppm-rate-result__text {
    white-space: nowrap;
    font-size: .6rem;
  }

  .btn-xs {
    padding: .3rem .5rem;
    font-size: .7rem;
    line-height: .65;
    border-radius: .2rem;
  }
}