// Required
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

/* Mobile view (xs and sm) */
@include media-breakpoint-down(sm) {
  /* need to use fixed height on list and container in order to show all make tiles */
  .vehicle-page__container.make-list__container {
    height: 496px;
    max-height: unset;
  }

  .vehicle-page__list.make-list__list {
    height: 448px;
    max-height: unset;
  }

  .make-list__image {
    width: 80px;
    height: 60px;
  }

  .make-list__display-name {
    /* make room for Volkswagen to fit */
    font-size: .8rem;
  }
}

/* Tablet/Desktop view (md and xl} */
@include media-breakpoint-up(md) {
  .make-list__image {
    width: 64px;
    height: 48px;
    /* position absolute so it won't mess with padding */
    position: absolute;
    top: 0px;
    left: 5px;
  }

  .make-list__display-name {
    font-size: 1rem;
    /* make room for the image */
    margin-left: 60px;
  }
}

/* Tablet and Desktop view (lg} */
@include media-breakpoint-only(lg) {
  /* use smaller images here to prevent makes from wrapping or causing horizontal scroll */
  .make-list__image {
    width: 52px;
    height: 39px;
    /* position absolute so it won't mess with padding */
    position: absolute;
    top: 4px;
    left: 3px;
  }

  .make-list__display-name {
    font-size: 1rem;
    /* make room for the image */
    margin-left: 42px;
  }
}
