@import "../../custom.scss";
@import "../../../node_modules/bootstrap/scss/variables";

input[type="date"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}

.coverage-page__container {
  height: 490px;
  overflow-y: auto;
  overflow-x: hidden;
}

.coverage-page__list {
  height: 511px;
  overflow-y: auto;
}


.coverage-page__interested {
  width: 8.35em !important;
  height: auto !important;
}

.coverage-page__interested label {
  width: 3em;
}

.coverage-page__plans--selected {
  border-color: $btn-highlight-color !important;
}

.coverage-page__plans--selected .card-header {
  background-color: $btn-highlight-color !important;
  color: white !important;
}

.coverage-page__plans--unselected .card-header {
  background-color: $gray-200 !important;
  color: black !important;
}

.coverage-page__plans--unselected .card-text,
.coverage-page__plans--selected .card-text {
  font-size: 0.8rem;
}

.coverage-page__plans--unselected input,
.coverage-page__plans--selected input {
  opacity: 0;
  position: absolute;
}

.coverage-page__plans--row {
  margin-right: -2px;
  margin-left: -2px;
  margin-bottom: -4px;
}

.coverage-page__plans--row .col {
  padding-right: 2px;
  padding-left: 2px;
  padding-bottom: 4px;
}

.coverage-page__curEstPrem {
  width: 10rem !important;
}

.coverage-page__curCovLevel {
  width: 15rem !important;
}

.coverage-page__group3-inputs {
  width: 10rem !important;
}

.coverage-page__protectiveDevices {
  width: 11rem !important;
  height: auto !important;
}

.card-header, .card-text, .card-body {
  cursor: pointer;    
}



