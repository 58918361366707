// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

/* Mobile view (xs and sm} */
@include media-breakpoint-down(sm) {
    .carrier-list__image {
        width: 75px;
        height: 60px;
    }

    .carrier-list__display-name {
        /* make room for American Family to fit */
        font-size: .55rem;
    }
}

/* Tablet/Desktop view (md and larger} */
@include media-breakpoint-up(md) {
    .carrier-list__image {
        width: 70px;
        height: 56px;
        /* position absolute so it won't mess with padding */
        position: absolute;
        top: 0px;
        left: 5px;
    }

  .carrier-list__display-name {
    font-size: 1rem;
    /* make room for the image */
    margin-left: 60px;
  }
}
