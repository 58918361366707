.body-style-list__vehicle-attributes {
  font-size: 0.8rem;
}
.body-style-list__vehicle-attributes:empty {
  display: none;
}
.body-style-list__vehicle-attributes > div {
  text-indent: -1rem;
  margin-left: 1rem;
}
