.driver-page__contact label {
  width: 4.5em;
}

.driver-page__contactTime label {
  width: 6.0em;
}

.driver-page__incidentType label {
    min-width: 5em;
}

.driver-page__accidentType label {
    min-width: 5em;
}

.driver-page__gender label {
    min-width: 5em;
}

.driver-page__dollar {
    min-width: 10em;
}

/* removes IE's passord reveal since we have our own button for this. */
input[type=password]::-ms-reveal{
  display: none;
}
