/* SCSS variables for easy color changes across pages*/
$btn-background-color: white;
$btn-text-color: #868e96;
$btn-highlight-color: #1b6ec2;
$btn-previous-border-color: black;

// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// Optional - specific to things being customized
@import "node_modules/bootstrap/scss/_button-group.scss";
@import "node_modules/bootstrap/scss/_forms.scss";

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: $btn-highlight-color;
  border-color: $btn-highlight-color;
}

.btn.btn-previous {
  color: $btn-previous-border-color;
  background-color: $btn-background-color;
  border-color: $btn-previous-border-color
}

.btn-primary:hover {
  background-color: $btn-highlight-color;
}

.btn.btn-previous:hover {
  background-color: $btn-background-color;
}

// Set invalid box shadow on focused btn-option 
.btn-group.is-invalid > .btn.btn-option:focus-within {
  box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, .25);
}

.btn.btn-option {
  color: $btn-text-color;
  border-color: $btn-text-color;
  background-color: $btn-background-color;
  text-align: center;

  &:focus-within,
  &.focus-within {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
    z-index: 1000;  // need to bring option to front to prevent right side from cutting off
  }
}

.btn.btn-option:hover,
.btn.btn-option.active,
.btn.btn-option:checked {
  color: $btn-background-color;
  border-color: $btn-text-color;
  background-color: $btn-highlight-color;
}

/* make btn act like btn-sm on really small screens like iphone 5 */
@media only screen and (max-width: 330px) {
  .btn {
    @include button-size($input-btn-padding-y-sm, $input-btn-padding-x-sm, $font-size-sm, $line-height-sm, $btn-border-radius-sm);
  }
}

/* This is an extension of the bootstrap class '.was-validated .form-control:invalid, .form-control.is-invalid' 
   but since it is a compound selector, it can't just use @extend - or at least I don't see how.
*/
.was-validated .btn-group:invalid, .btn-group.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  /* modifications  */
  background-color: $input-bg;
  border: 1px solid #dc3545;
  border-radius: 0.25rem;
}


.btn-group.is-invalid .btn-option {
  margin-top: -1px;
  margin-bottom: -1px;
  border-color: $form-feedback-invalid-color;
}
.btn-group.is-invalid .btn-option:first-of-type {
  margin-left: -1px;
}

input[type='date'] {
  width: 11rem !important;

  &.is-invalid {
    width: 13rem !important;
  }
}

input[type='text'][placeholder='mm/dd/yyyy'] {
  width: 11rem !important;
}